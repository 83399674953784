<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Solicitor
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="solicitor-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
          <v-text-field
            label="Address Line 1 *"
            v-model="fields.address1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address1')"
            :error-messages="errors['address1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.address2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address2')"
            :error-messages="errors['address2']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('city')"
            :error-messages="errors['city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('postcode')"
            :error-messages="errors['postcode']"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.county"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('county')"
            :error-messages="errors['county']"
          ></v-text-field>
          <v-autocomplete
            label="Country *"
            v-model="fields.country"
            :items="availableCountries"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('country')"
            :error-messages="errors['country']"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="solicitor-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      solicitor: {},
      fields: {
        name: null,
        address1: null,
        address2: null,
        city: null,
        county: null,
        postcode: null,
        country: "United Kingdom",
        phone: null,
        email: null,
      },
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(solicitor = null) {
      if (solicitor !== null) {
        this.solicitor = solicitor;
        this.fields.name = this.solicitor.name;
        this.fields.email = this.solicitor.email;
        this.fields.phone = this.solicitor.phone;
        this.fields.address1 = this.solicitor.address1;
        this.fields.address2 = this.solicitor.address2;
        this.fields.city = this.solicitor.city;
        this.fields.postcode = this.solicitor.postcode;
        this.fields.county = this.solicitor.county;
        this.fields.country = this.solicitor.country;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.solicitor = {};
      this.fields = {
        name: null,
        address1: null,
        address2: null,
        city: null,
        county: null,
        postcode: null,
        country: "United Kingdom",
        phone: null,
        email: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.solicitorId = this.solicitor.id;
      }

      this.$store
        .dispatch("melrose/solicitorsStore/saveSolicitor", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
