<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Solicitors</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Solicitors"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.solicitorDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Solicitor</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Solicitors</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="solicitors"
        class="mt-4"
        no-data-text="No Solicitors found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.solicitorDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <solicitor-dialog ref="solicitorDialog" />

    <v-dialog v-model="deleteSolicitor.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Solicitor</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteSolicitor.solicitor.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteSolicitor.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SolicitorDialog from "./components/SolicitorDialog.vue";

export default {
  components: {
    SolicitorDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Solicitors",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "Address", value: "short_address" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteSolicitor: {
        dialog: false,
        solicitor: {},
        loading: false,
      },
    };
  },

  computed: {
    solicitors() {
      let solicitors = this.$store.getters["melrose/solicitorsStore/all"];

      if (this.searchTerm !== "") {
        solicitors = solicitors.filter((s) => {
          const name = s.name.toLowerCase();
          const email = s.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return solicitors;
    },
  },

  methods: {
    openDelete(solicitor) {
      this.deleteSolicitor.solicitor = solicitor;
      this.deleteSolicitor.dialog = true;
    },

    resetDelete() {
      this.deleteSolicitor.dialog = false;
      this.deleteSolicitor.solicitor = {};
      this.deleteSolicitor.loading = false;
    },

    saveDelete() {
      this.deleteSolicitor.loading = true;

      this.$store
        .dispatch("melrose/solicitorsStore/deleteSolicitor", {
          appId: this.$route.params.id,
          solicitorId: this.deleteSolicitor.solicitor.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteSolicitor.loading = false;
        });
    },
  },
};
</script>
